<template>
  <section class="section">
    <h2 class="section__title">Two-factor authentication</h2>
    <v-form ref="form" class="authentication-form ">
      <v-text-field
        v-model="authenticationCode"
        background-color="white"
        label="Authentication code"
        placeholder=" "
        class="authentication-form__field"
        outlined
        :rules="loginRules"
      />

      <div
        v-if="hasFormError"
        class="authentication-form__error-message mb-3 red--text text--accent-2"
      >
        {{ formErrorMessage }}
      </div>
      <div>
        <v-btn
          tile
          large
          color="light-blue darken-4 mt-2"
          class="white--text"
          :loading="isLoading"
          @click="onSignIn"
        >
          Sign In
        </v-btn>
      </div>
    </v-form>
    <div class="section__support-info">
      <p>Having problems accessing your account?</p>
      <a class="link" @click="goToRecoveryCodePage">Enter a two-factor recovery code</a>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

import types from '@/store/types';
import { NETWORK_ERROR } from '@/constants/errors';

const LOGIN_RULES = [v => !!v || 'This field is required'];

const FORM_ERRORS_MESSAGES = {
  CREDENTIALS_ERROR: 'Two-factor code verification failed. Please try again.',
  NETWORK_ERROR: 'Internet disconnected'
};

export default {
  name: 'TwoFactorAuthenticationForm',
  props: {
    username: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loginRules: LOGIN_RULES,
      authenticationCode: '',
      isLoading: false,
      hasFormError: false,
      formErrorMessage: ''
    };
  },
  methods: {
    ...mapActions({
      signIn: types.actions.SIGN_IN
    }),
    async validateForm() {
      this.hasFormError = false;

      await this.$nextTick();

      return this.$refs.form.validate();
    },
    async onSignIn() {
      const isFormValid = await this.validateForm();

      if (!isFormValid) {
        return;
      }

      this.isLoading = true;

      const { error } = await this.signIn({
        username: this.username,
        password: this.password,
        twoFactorAuthenticationCode: this.authenticationCode
      });

      this.isLoading = false;

      if (error) {
        this.hasFormError = true;

        this.formErrorMessage =
          error === NETWORK_ERROR
            ? FORM_ERRORS_MESSAGES.NETWORK_ERROR
            : FORM_ERRORS_MESSAGES.CREDENTIALS_ERROR;

        return;
      }

      this.$router.push({ name: 'Dashboard' });
    },
    goToRecoveryCodePage() {
      this.$emit('open-recovery-code-page');
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  &__title {
    margin-bottom: 30px;
    font-size: 21px;
    line-height: 25px;
    color: #324752;
  }

  &__support-info {
    font-size: 14px;
    line-height: 16px;
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid #c8d5dd;
  }
}

.link {
  color: #526d9f;
  font-weight: 500;
}

.authentication-form {
  margin: 0 auto;
  max-width: 320px;

  &__error-message {
    font-size: 12px;
  }
}
</style>
