<template>
  <login-page-template>
    <two-factor-authentication-form
      :username="username"
      :password="password"
      @open-recovery-code-page="goToRecoveryCodePage"
    />
  </login-page-template>
</template>

<script>
import LoginPageTemplate from '@/components/login-page/LoginPageTemplate.vue';
import TwoFactorAuthenticationForm from '@/components/login-page/TwoFactorAuthenticationForm.vue';

export default {
  name: 'TwoFactorAuthenticationPage',
  components: { LoginPageTemplate, TwoFactorAuthenticationForm },
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (!this.username && !this.password) {
      this.$router.push({ name: 'SignIn' });
    }
  },
  methods: {
    goToRecoveryCodePage() {
      this.$router.push({
        name: 'RecoveryCode',
        params: { username: this.username, password: this.password }
      });
    }
  }
};
</script>
